import React from 'react'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

// import images
import home4 from "../assets/images/home/home-4.png";
import img1 from "../assets/images/user/img-1.jpg"
import img2 from "../assets/images/user/img-2.jpg"
import img3 from "../assets/images/user/img-3.jpg"
import img4 from "../assets/images/user/img-4.jpg"
import img5 from "../assets/images/user/img-5.jpg"
 
AOS.init({
  duration: 1800,
});


const work = () => {
  return (
    <React.Fragment>
    <section className="section work bg-light">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">COMMENT ÇA MARCHE</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="work-pra">
                <p className="text-muted">
                  Hospital Market simplifie l'achat de dispositifs médicaux en offrant une plateforme conviviale où les professionnels de la santé peuvent parcourir une variété de produits neufs et d'occasion.
                  Avec des descriptions détaillées et des images, les utilisateurs sélectionnent les équipements nécessaires et les ajoutent au panier.
                  Le paiement sécurisé et la livraison rapide garantissent une expérience sans souci.
                  Suivre l'état de la commande et accéder à un support client dédié simplifie davantage le processus.
                  Hospital Market transforme l'approvisionnement médical en offrant commodité, qualité et confiance à chaque étape.
                </p>
                <h4 className="my-4">AVANTAGES DE L'APPLICATION</h4>
                <ul className="inline-item">
                  <li className="list-inline-item">
                    <i className="uil uil-bell"></i>
                    <p>
                      Sélection Complète : <span className="text-muted">Accédez à une large gamme de dispositifs médicaux neufs et d'occasion en un seul endroit.</span>
                    </p>
                  </li>
                  <li className="list-inline-item">
                    <i className="uil uil-star"></i>
                    <p>
                        Facilité d'Achat : <span className="text-muted">Parcourez des descriptions détaillées et effectuez des achats sécurisés en quelques clics.</span>
                    </p>
                  </li>
                  <li className="list-inline-item">
                    <i className="uil uil-envelope-minus"></i>
                    <p>
                      Support Client Expert : <span className="text-muted">Bénéficiez d'une assistance dédiée pour vos questions et besoins.</span>
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={6} data-aos="fade-right">
              <div className="testimonial-contaent">
                <div className="testimonial-right">
                  <div className="testimonial-circle">
                    <div className="media">
                      <img src={home4} className="img-fluid" alt="" />
                    </div>
                    <span className="ring1 animate-v2">
                      <img src={img1} className="img-fluid" alt="" />
                    </span>
                    <span className="ring2 animate-v3">
                      <img src={img2} className="img-fluid" alt="" />
                    </span>
                    <span className="ring3 animate-v2">
                      <img src={img3} className="img-fluid" alt="" />
                    </span>
                    <span className="ring4 animate-v3">
                      <img src={img4} className="img-fluid" alt="" />
                    </span>
                    <span className="ring5 animate-v2">
                      <img src={img5} className="img-fluid" alt="" />
                    </span>
                    <span className="ring6 animate-v3"></span>
                    <span className="ring7 animate-v2"></span>
                    <span className="ring8 animate-v3"></span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default work;