import React from 'react'
import {
  Container,
  Row,
  Col,
  Badge
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay } from "swiper";


// import images
import screenshot3 from "../assets/images/testi/dd/screenshot3.jpeg"
import screenshot4 from "../assets/images/testi/dd/screenshot4.jpeg"
import screenshot5 from "../assets/images/testi/dd/screenshot5.jpeg"
import screenshot7 from "../assets/images/testi/dd/screenshot7.jpeg"
import screenshot9 from "../assets/images/testi/dd/screenshot9.png"
import screenshot10 from "../assets/images/testi/dd/screenshot10.jpeg"



const Home = () => {

  const screenshotList = [
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot7,
    screenshot9,
    screenshot10
  ]

  return (
    <React.Fragment>
      <section className="home-2 home-slider" id="home">
        {/* problem */}
        <div className="box">
          <div className="wave -one"></div>
          <div className="wave -two"></div>
          <div className="wave -three"></div>
        </div>
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col md={6}>
              <div className="home-heading">
                <Badge bg="soft-primary" className="rounded-pill mb-3">Android & IOS Device</Badge>
                <h3>Accédez rapidement et en toute sécurité à tous vos dispositifs médicaux</h3>
                <p className="home-title"></p>
                <p className="text-muted">
                  Simplifiez votre recherche et obtenez rapidement les équipements médicaux essentiels pour offrir des soins de qualité supérieure.
                  Rejoignez Hospital Market dès aujourd'hui et faites évoluer vos pratiques médicales avec facilité.
                </p>
                <div className="btns-group mb-25">
                  <button to="#" className="btn bg-gradiant">Télécharger gratuitement</button>
                  {/*<button to="#" className="btn bg-outline-gradiant" style={{ marginLeft: '4px' }}>Buy Only for $3.99</button>*/}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="swiper swiper-container homeSwiper">
                <div className="swiper-wrapper">
                  <Swiper
                    loop={true}
                    effect={"coverflow"}
                    spaceBetween={50}
                    centeredSlides={true}
                    slidesPerView={2}
                    speed={5000}
                    autoplay={{
                      delay: 500,
                      disableOnInteraction: false,
                    }}
                    modules={[EffectCoverflow, Autoplay]}
                    className="mySwiper"
                  >
                    {screenshotList.map((ss)=>{
                      return (
                          <SwiperSlide className="swiper-slide border-radius">
                            <div className="app-screenshot-item text-center d-flex justify-content-center">
                              <div className="app-screenshot-overlayer">
                                <Link className="mfp-image img-fluid" to={ss} title=""></Link>
                              </div>
                              <div className="screenshot-img">
                                <Link to={ss} className="lightbox img-fluid">
                                  <img src={ss} alt="" className='img-fluid' />
                                </Link>
                              </div>
                            </div>
                          </SwiperSlide>
                      )
                    })}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img1} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img">*/}
                    {/*      <Link to={img1} className="lightbox img-fluid">*/}
                    {/*        <img src={img1} alt="" className='img-fluid' />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img2} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img">*/}
                    {/*      <Link to={img2} className="lightbox img-fluid">*/}
                    {/*        <img src={img2} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img3} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img">*/}
                    {/*      <Link to={img3} className="lightbox img-fluid">*/}
                    {/*        <img src={img3} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img4} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img">*/}
                    {/*      <Link to={img4} className="lightbox img-fluid">*/}
                    {/*        <img src={img4} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img5} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img">*/}
                    {/*      <Link to={img5} className="lightbox img-fluid">*/}
                    {/*        <img src={img5} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img6} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img">*/}
                    {/*      <Link to={img6} className="lightbox img-fluid">*/}
                    {/*        <img src={img6} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img7} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img">*/}
                    {/*      <Link to={img7} className="lightbox img-fluid">*/}
                    {/*        <img src={img7} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img7} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img">*/}
                    {/*      <Link to={img7} className="lightbox img-fluid">*/}
                    {/*        <img src={img7} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img8} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img">*/}
                    {/*      <Link to={img8} className="lightbox img-fluid">*/}
                    {/*        <img src={img8} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                  </Swiper>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Container fluid>
        <Row>
          <div className="mobile">
            <Link to="#about">
              <span className="phone">
                <i className="mdi mdi-cellphone"></i>
              </span>
            </Link>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Home;