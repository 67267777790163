import Navbar1 from "../layout/Navbar";
import Home from "../components/home";
import AboutUs from "../components/AboutUs";
import Features from "../components/Features";
import Work from "../components/work";
import Application from "../components/Application";
import Team from "../components/Team";
import Contact from "../components/Contact";
import Footer from "../layout/Footer";
import React from "react";


const Landing = () => {
    return (
        <>
            <Navbar1 />
            <Home />
            <AboutUs />
            {/* problem */}
            <Features />
            <Work />
            {/*<Pricing />*/}

            {/* problem */}
            <Application />
            {/*<Team />*/}
            <Contact />
            <Footer />
        </>
    )
}

export default Landing;