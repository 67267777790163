import React from 'react';
import {
  Container,
  Row,
  Col,
  Image
} from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/core';
import { Link } from 'react-router-dom';


// import images
import screenshot1 from "../assets/images/testi/dd/screenshot1.jpeg"
import screenshot2 from "../assets/images/testi/dd/screenshot2.jpeg"
import screenshot3 from "../assets/images/testi/dd/screenshot3.jpeg"
import screenshot4 from "../assets/images/testi/dd/screenshot4.jpeg"
import screenshot5 from "../assets/images/testi/dd/screenshot5.jpeg"
import screenshot6 from "../assets/images/testi/dd/screenshot6.jpeg"
import screenshot7 from "../assets/images/testi/dd/screenshot7.jpeg"
import screenshot8 from "../assets/images/testi/dd/screenshot8.jpeg"
import screenshot9 from "../assets/images/testi/dd/screenshot9.png"
import screenshot10 from "../assets/images/testi/dd/screenshot10.jpeg"


const Application = () => {

  const screenshotList = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
    screenshot10
  ]

  return (
    <React.Fragment>
      <section className="section app-slider bg-light" id="app">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">CAPTURES D'ÉCRAN DE NOTRE APPLICATION</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="swiper swiper-container swiper-container1">
                {/*<div className="fream-phone">*/}
                {/*  <Image src={phoneFream} alt="" fluid />*/}
                {/*</div>*/}
                <div className="swiper-wrapper">
                  <Swiper
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={50}
                    centeredSlides={true}
                    speed={5000}
                    autoplay={{
                      delay: 500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                  >
                    {screenshotList.map((ss)=>{
                      return (
                          <SwiperSlide className="swiper-slide border-radius">
                            <div className="app-screenshot-item text-center d-flex justify-content-center">
                              <div className="app-screenshot-overlayer">
                                <Link className="mfp-image img-fluid" to={ss} title=""></Link>
                              </div>
                              <div className="screenshot-img">
                                <Link to={ss} className="lightbox img-fluid">
                                  <img src={ss} alt="" className='img-fluid' />
                                </Link>
                              </div>
                            </div>
                          </SwiperSlide>
                      )
                    })}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img1} title="" > </Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img mb-4">*/}
                    {/*      <Link to={img1} className="lightbox img-fluid" >*/}
                    {/*        <Image src={img1} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img2} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img mb-4">*/}
                    {/*      <Link to={img2} className="lightbox img-fluid">*/}
                    {/*        <Image src={img2} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img3} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img mb-4">*/}
                    {/*      <Link to={img3} className="lightbox img-fluid">*/}
                    {/*        <Image src={img3} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img4} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img mb-4">*/}
                    {/*      <Link to={img4} className="lightbox img-fluid">*/}
                    {/*        <Image src={img4} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img5} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img mb-4">*/}
                    {/*      <Link to={img5} className="lightbox img-fluid">*/}
                    {/*        <Image src={img5} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img6} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img mb-4">*/}
                    {/*      <Link to={img6} className="lightbox img-fluid">*/}
                    {/*        <Image src={img6} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img7} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img mb-4">*/}
                    {/*      <Link to={img7} className="lightbox img-fluid">*/}
                    {/*        <Image src={img7} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img7} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img mb-4">*/}
                    {/*      <Link to={img7} className="lightbox img-fluid">*/}
                    {/*        <Image src={img7} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide className="swiper-slide border-radius">*/}
                    {/*  <div className="app-screenshot-item text-center d-flex justify-content-center">*/}
                    {/*    <div className="app-screenshot-overlayer">*/}
                    {/*      <Link className="mfp-image img-fluid" to={img8} title=""></Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="screenshot-img mb-4">*/}
                    {/*      <Link to={img8} className="lightbox img-fluid">*/}
                    {/*        <Image src={img8} alt="" className="img-fluid" />*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</SwiperSlide>*/}
                  </Swiper>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Application;