import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

// import images
import screenshot from "../assets/images/testi/dd/screenshot11.png"


AOS.init({
  duration: 1800,
});



const Features = () => {
  return (
    <React.Fragment>
      <section className="section features" id="features">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">Caractéristiques de notre application</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col lg={4} md={10} data-aos="fade-right" >
              <div className="features-box text-end">
                <div className="features-icon float-end ms-2">
                  <i className="uil uil-shopping-cart-alt"></i>
                </div>
                <div className="pe-3 me-5">
                  <h5 className="f-15 text-uppercase">Large Sélection de Produits</h5>
                  <p className="text-muted mt-3">
                    Découvrez une variété impressionnante de dispositifs médicaux soigneusement sélectionnés pour répondre aux besoins divers des professionnels de la santé.
                    Que vous cherchiez du matériel neuf ou d'occasion, Hospital Market a tout ce dont vous avez besoin.
                  </p>
                </div>
              </div>

              <div className="features-box mt-5 text-end">
                <div className="features-icon float-end ms-2">
                  <i className="uil uil-presentation-check"></i>
                </div>
                <div className="pe-3 me-5">
                  <h5 className="f-15 text-uppercase">Qualité Garantie</h5>
                  <p className="text-muted mt-3">Nous mettons l'accent sur la qualité et la sécurité. Tous nos produits sont soumis à des contrôles rigoureux pour assurer des normes élevées et des performances fiables.</p>
                </div>
              </div>

              <div className="features-box mt-5 text-end">
                <div className="features-icon float-end ms-2">
                  <i className="uil uil-search-plus"></i>
                </div>
                <div className="pe-3 me-5">
                  <h5 className="f-15 text-uppercase">Navigation Intuitive</h5>
                  <p className="text-muted mt-3">Parcourez notre interface conviviale pour trouver rapidement ce dont vous avez besoin. Des descriptions détaillées et des images claires vous guident tout au long de votre processus de sélection.</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center">

                <img src={screenshot} className="img-fluid" data-aos="zoom-in" alt="" />
              </div>
            </Col>
            <Col lg={4} md={10} data-aos="fade-left">
              <div className="features-box">
                <div className="features-icon float-start me-2">
                  <i className="uil uil-bolt"></i>
                </div>
                <div className="ps-3 ms-5">
                  <h5 className="f-15 text-uppercase">Facilité d'Utilisation</h5>
                  <p className="text-muted mt-3">Notre plateforme vous permet de commander en quelques clics seulement. Simplifiez vos achats et économisez du temps précieux pour vous concentrer sur vos patients.</p>
                </div>
              </div>
              <div className="features-box mt-5">
                <div className="features-icon float-start me-2">
                  <i className="uil uil-users-alt"></i>
                </div>
                <div className="ps-3 ms-5">
                  <h5 className="f-15 text-uppercase">Assistance Clientèle</h5>
                  <p className="text-muted mt-3">
                    Notre équipe dédiée est là pour répondre à vos questions et vous guider à chaque étape de votre parcours d'achat. Nous nous engageons à vous offrir la meilleure expérience possible.
                  </p>
                </div>
              </div>
              <div className="features-box mt-5">
                <div className="features-icon float-start me-2">
                  <i className="uil uil-feedback"></i>
                </div>
                <div className="ps-3 ms-5">
                  <h5 className="f-15 text-uppercase">Faites Évoluer vos Pratiques</h5>
                  <p className="text-muted mt-3">
                    Avec Hospital Market, renforcez votre capacité à offrir des soins de qualité supérieure en vous fournissant les dispositifs médicaux nécessaires pour vos besoins cliniques.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Features;