import React from 'react';
import Landing from "./pages/landing";
import Privacy from "./pages/privacy";
import {BrowserRouter, Route, Routes} from "react-router-dom";


function App() {
  return (
      <Routes>
          <Route exact path={`/`} element={<Landing/>}/>
          <Route exact path={`/privacy`} element={<Privacy/>}/>
      </Routes>
  );
}

export default App;
