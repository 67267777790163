import Navbar1 from "../layout/Navbar";
import Contact from "../components/Contact";
import Footer from "../layout/Footer";
import React from "react";
import PrivacyPolicy from "../components/privacyPolicy";
import {Container} from "react-bootstrap";


const Privacy = () => {
    return (
        <>
            <Navbar1 />
            <Container>
                <PrivacyPolicy/>
            </Container>
            <Contact />
            <Footer />
        </>
    )
}

export default Privacy;