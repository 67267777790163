

const PrivacyPolicy = () => {
    return (
        <>
            <div className='mt--36'>
                <br/><br/><br/><br/>
                <h1>Politique de confidentialité</h1>
                <p>
                    Politique de confidentialité de l'application de vente de dispositifs médicaux neufs et d'occasion
                </p>
                <p>
                    Date d'entrée en vigueur : 17 août 2023.
                </p>
                <p>
                    Chez HOSPITAL MARKET, nous accordons une grande importance à la confidentialité des données de nos utilisateurs.
                    Cette politique de confidentialité décrit comment nous recueillons, utilisons,
                    stockons et protégeons les informations personnelles des utilisateurs de notre application
                    de vente de dispositifs médicaux neufs et d'occasion (ci-après dénommée " HOSPITAL MARKET ").
                </p>

                <p className="p-3">
                    1. Collecte d'informations
                </p>
                <p>Nous collectons les informations suivantes lorsque vous utilisez notre application :</p>
                <p>
                    a. Informations d'inscription : Lors de votre inscription,
                    nous pouvons vous demander de fournir des informations telles que votre nom,
                    votre adresse e-mail, votre adresse de livraison et votre numéro de téléphone.
                    Ces informations sont nécessaires pour créer et gérer votre compte utilisateur.
                </p>
                <p>
                    a. Informations d'inscription : Lors de votre inscription,
                    nous pouvons vous demander de fournir des informations telles que votre nom,
                    votre adresse e-mail, votre adresse de livraison et votre numéro de téléphone.
                    Ces informations sont nécessaires pour créer et gérer votre compte utilisateur.
                </p>
                <p>
                    a. Informations d'inscription : Lors de votre inscription,
                    nous pouvons vous demander de fournir des informations telles que votre nom,
                    votre adresse e-mail, votre adresse de livraison et votre numéro de téléphone.
                    Ces informations sont nécessaires pour créer et gérer votre compte utilisateur.
                </p>
                <p>
                    a. Informations d'inscription : Lors de votre inscription,
                    nous pouvons vous demander de fournir des informations telles que votre nom,
                    votre adresse e-mail, votre adresse de livraison et votre numéro de téléphone.
                    Ces informations sont nécessaires pour créer et gérer votre compte utilisateur.
                </p>

                <p>
                    2. Utilisation des informations
                </p>
                <p>
                    Nous utilisons les informations collectées dans le but de :
                </p>
                <p>
                    a. Fournir nos services : Nous utilisons vos informations pour faciliter les transactions
                    d'achat et de vente sur l'application, pour vous permettre de communiquer avec d'autres
                    utilisateurs et pour répondre à vos demandes de service à la clientèle.
                </p>
                <p>
                    a. Fournir nos services : Nous utilisons vos informations pour faciliter
                    les transactions d'achat et de vente sur l'application, pour vous permettre de communiquer
                    avec d'autres utilisateurs et pour répondre à vos demandes de service à la clientèle.
                </p>
                <p>
                    a. Fournir nos services : Nous utilisons vos informations pour faciliter
                    les transactions d'achat et de vente sur l'application, pour vous permettre de communiquer
                    avec d'autres utilisateurs et pour répondre à vos demandes de service à la clientèle.
                </p>
                <p>
                    a. Fournir nos services : Nous utilisons vos informations pour faciliter les transactions
                    d'achat et de vente sur l'application, pour vous permettre de communiquer avec d'autres
                    utilisateurs et pour répondre à vos demandes de service à la clientèle.
                </p>


                <p className="p-3">
                    3. Partage d'informations
                </p>
                <p>
                    Nous ne vendons, ne louons ni ne partageons vos informations personnelles avec des tiers, sauf dans les cas suivants :
                </p>
                <p>
                    a. Fournisseurs de services : Nous pouvons partager vos informations avec des fournisseurs
                    de services tiers qui travaillent en notre nom pour faciliter les opérations de l'application,
                    tels que le traitement des paiements, la livraison des produits et l'analyse des données.
                </p>
                <p>
                    b. Conformité légale : Nous pouvons divulguer vos informations si nous sommes légalement
                    tenus de le faire pour nous conformer aux lois applicables, aux demandes des autorités
                    gouvernementales ou pour protéger nos droits, notre sécurité ou celle d'autrui.
                </p>
                <p>
                    c. Transaction commerciale : Si nous sommes impliqués dans une fusion,
                    une acquisition, une vente d'actifs ou toute autre transaction commerciale,
                    vos informations peuvent être transférées en tant qu'actif de l'entreprise,
                    sous réserve des obligations de confidentialité existantes.
                </p>

                <p className="p-3">
                    4. Sécurité des informations
                </p>
                <p>
                    Nous prenons des mesures de sécurité appropriées pour protéger vos informations personnelles contre toute perte,
                    utilisation abusive, accès non autorisé, divulgation ou modification. Cependant,
                    veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique
                    n'est totalement sécurisée. Nous ne pouvons donc garantir une sécurité absolue des informations.
                </p>


                <p className="p-3">
                    5. Vos choix et droits
                </p>
                <p>
                    a. Accès et mise à jour des informations : Vous pouvez accéder à vos informations personnelles
                    via votre compte utilisateur et les mettre à jour si nécessaire.
                </p>
                <p>
                    b. Désinscription : Vous pouvez choisir de ne plus recevoir de communications promotionnelles
                    de notre part en suivant les instructions de désinscription fournies dans ces communications.
                </p>
                <p>
                    c. Cookies : Nous utilisons des cookies et des technologies similaires pour collecter
                    des informations sur votre utilisation de l'application. Vous pouvez modifier les paramètres
                    de votre navigateur pour refuser les cookies, mais cela peut affecter certaines fonctionnalités
                    de l'application.
                </p>

                <p className="p-3">
                    6. Conservation des données
                </p>
                <p>
                    Nous conservons vos informations personnelles aussi longtemps que nécessaire pour fournir nos services,
                    respecter nos obligations légales, résoudre les litiges et faire respecter nos accords.
                </p>


                <p className="p-3">
                    7. Modifications de la politique de confidentialité
                </p>
                <p>
                    Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment.
                    Toute modification sera publiée sur l'application et prendra effet dès sa publication.
                    Nous vous encourageons à consulter régulièrement cette politique pour rester informé
                    de nos pratiques en matière de confidentialité.
                </p>


                <p className="p-3">
                    8. Nous contacter
                </p>
                <p>
                    Si vous avez des questions, des préoccupations ou des demandes concernant
                    cette politique de confidentialité ou notre utilisation de vos informations personnelles,
                    veuillez nous contacter via les coordonnées fournies dans l'application.
                </p>
            </div>
        </>
    )
}

export default PrivacyPolicy